

    import $request from "@/modules/request/"
    
    export default {
        
        countries: [],
        cities: localStorage.getItem('current:cities') ? JSON.parse( localStorage.getItem('current:cities') ) : [],
        
        get() {
            $request.get("utils.db").then(result => {
               Object.keys(result).forEach(key => {
                  if( this.hasOwnProperty( key ) ) {
                      this[key] = result[key];
                  }
               });
            });
        },
        
        setCities(cities){
            this.cities = [{id: -1, name: 'Вся РФ'}, ...cities]
            localStorage.setItem( 'current:cities', JSON.stringify(this.cities) );
        }
        
    }