<template>
  <div>
    <!-- Свайпер  -->
    <div style="padding: 0; position: relative">
      <swiper class="stretch-swiper" ref="swiper" :options="topSwiperOptions">
        <swiper-slide
          v-for="(item, index) in stretchCards"
          :key="index"
          style="max-width: 90%"
        >
          <stretch-offer-card-view :item="item" />
        </swiper-slide>
      </swiper>
    </div>

    <!-- <div class="d-flex justify-content-between">
      <stretch-offer-card-view
        v-for="(offer, index) in stretchCards"
        :key="index"
        :item="offer"
        :style="multiWidth(stretchCards)"
      />
    </div> -->

    <b-card-title
      style="
        font-weight: 600;
        font-size: 21px;
        line-height: 28px;
        font-style: normal;
      "
      >Лучшее на сегодня в
      <a @click="showModal('cities')" style="color: var(--link-primary)"
        >{{ Offers.city.name }}
        <feather-icon
          style="color: var(--link-primary)"
          size="22"
          icon="ChevronDownIcon"
        />
      </a>
    </b-card-title>

    <!-- <div class="d-flex"> -->
    <b-card-title>
      <validation-observer
        ref="filter"
        class="slider-hover-prevent v-select-filter-form"
      >
        <b-row class="row-form">
          <b-col cols="12">
            <b-form-group class="form-group-with-label mult-select">
              <div class="d-flex justify-content-between">
                <div style="flex-grow: 1">
                  <validation-provider rules="">
                    <v-select
                      :options="Offers.categories"
                      v-model="filter.categories"
                      label="name"
                      :clearable="false"
                      placeholder="Категория"
                      multiple
                    >
                      <template slot="no-options"> Нет результата </template>
                    </v-select>
                  </validation-provider>
                </div>
              </div>
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group class="form-group-with-label mult-select">
              <div class="d-flex justify-content-between">
                <div style="flex-grow: 1">
                  <validation-provider rules="">
                    <v-select
                      :options="options.actions"
                      v-model="filter.actions"
                      label="name"
                      :clearable="false"
                      placeholder="Целевое действие"
                      multiple
                    >
                      <template slot="no-options"> Нет результата </template>
                    </v-select>
                  </validation-provider>
                </div>
              </div>
            </b-form-group>
          </b-col>

          <b-col cols="9">
            <b-form-group class="form-group-with-label mult-select">
              <div class="d-flex justify-content-between">
                <div style="flex-grow: 1">
                  <validation-provider rules="">
                    <v-select
                      :options="Offers.companies"
                      v-model="filter.companies"
                      label="name"
                      :clearable="false"
                      placeholder="Компания"
                      multiple
                    >
                      <template slot="no-options"> Нет результата </template>
                    </v-select>
                  </validation-provider>
                </div>
              </div>
            </b-form-group>
          </b-col>

          <b-col style="margin-left: 8px">
            <div class="bubble-filter-huge">
              <div class="icon-huge" style="width: 20px; height: 18px"></div>
            </div>
          </b-col>
        </b-row>
      </validation-observer>
    </b-card-title>

    <!-- </div> -->

    <b-overlay
      :show="is_loading"
      spinner-variant="primary"
      variant="transparent"
      style="flex-wrap: wrap; position: unset !important"
    >
      <b-card-body
        v-if="Offers.items.length === 0 && !is_loading"
        class="d-flex flex-column p-4"
      >
        <div class="d-flex flex-column justify-content-center">
          <div class="nodata-icon ml-auto mr-auto"></div>
          <p class="text-muted mt-1 ml-auto mr-auto text-center">
            Нет предложений
          </p>
        </div>
      </b-card-body>

      <div class="d-grid-cards-catalog" v-else-if="!is_loading">
        <offer-card-view
          v-for="(offer, index) in firstItems"
          :key="index"
          :item="offer"
        />
        <card-carousel
          class="w-100 mb-0"
          v-if="!is_loading && Offers.carousel_items.length > 0"
          :items="Offers.carousel_items"
        />
      </div>
    </b-overlay>

    <info-horizontal-card-view />

    <b-overlay
      spinner-variant="primary"
      variant="transparent"
      style="flex-wrap: wrap"
      :style="secondItems.length > 0 ? 'min-height: 50vh' : ''"
      class="d-grid-cards-catalog"
    >
      <offer-card-view
        v-for="(offer, index) in secondItems"
        :key="index"
        :item="offer"
      />
    </b-overlay>

    <div class="mt-2 btn-more-footer" v-if="Offers.has_more_pages">
      <center>
        <b-button
          v-if="!is_additional_loading"
          @click="loadMore"
          variant="primary-dark"
        >
          Показать еще
        </b-button>
        <b-button v-else variant="primary-dark" disabled>
          <b-spinner small type="grow"></b-spinner>
          Загрузка...
        </b-button>
      </center>
    </div>

    <b-modal
      id="cities-container"
      v-model="modalsVisibility.cities"
      centered
      title="Выбрать город"
      ok-only
      ok-title="Готово"
      modal-class="with-no-paddings"
    >
      <cities-modal-content @close="closeModal" />
    </b-modal>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";

import VueSlider from "vue-slider-component";
import vSelect from "vue-select";

import OfferCardView from "@/components/OfferCard";
import StretchOfferCardView from "@/components/StretchOfferCard";
import InfoHorizontalCardView from "@/components/InfoHorizontalCard";

import Offers from "@/modules/offers/";
import CitiesModalContent from "./main/modals/cities";

import CardCarousel from "@/components/CardCarousel.vue";

import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";

export default {
  data() {
    return {
      Offers,
      is_loading: false,
      is_additional_loading: false,
      modalsVisibility: {
        cities: false,
      },
      options: {
        actions: [
          { id: 1, name: "act 1" },
          { id: 2, name: "act 2" },
          { id: 3, name: "act 3" },
        ],
      },
      filter: {
        categories: [],
        companies: [],
        actions: [],
      },
    };
  },
  props: {},
  methods: {
    multiWidth(items) {
      if (items.length == 1) return { width: "100%" };
      else if (items.length == 2) return { width: "49%" };
      else return { width: "32%" };
    },
    loadMore() {
      this.is_additional_loading = true;
      let filter = Object.assign({}, this.filter);

      filter.companies = filter.companies.map((f) => f.id);
      filter.actions = filter.actions.map((f) => f.id);
      filter.categories = filter.categories.map((f) => f.id);

      Offers.load({ page: Offers.current_page + 1, filter: filter }, () => {
        this.is_additional_loading = false;
      });
    },
    closeModal(action) {
      Object.keys(this.modalsVisibility).forEach((key) => {
        this.modalsVisibility[key] = false;
      });
    },

    showModal(action) {
      Object.keys(this.modalsVisibility).forEach((key) => {
        this.modalsVisibility[key] = false;
      });

      if (this.modalsVisibility.hasOwnProperty(action)) {
        this.modalsVisibility[action] = true;
      }
    },
  },
  components: {
    Swiper,
    SwiperSlide,
    VueSlider,
    ValidationProvider,
    ValidationObserver,
    vSelect,
    OfferCardView,
    StretchOfferCardView,
    InfoHorizontalCardView,
    CitiesModalContent,
    CardCarousel,
  },

  computed: {
    topSwiperOptions() {
      return {
        init: true,
        slidesPerView: "auto",
        centeredSlides: false,
        spaceBetween: 20,
        slidesOffsetBefore: 0,
      };
    },
    stretchCards() {
      return Offers.top_items;
    },
    // firstItems() {
    //   return Offers.items.slice(0, 5).reverse();
    // },
    // secondItems() {
    //   return Offers.items.slice(5, Offers.items.length);
    // },
    firstItems() {
      if (Offers.carousel_items.length > 0) {
        return Offers.items.slice(0, 5).reverse();
      } else {
        return Offers.items.slice(0, 6).reverse();
      }
    },
    secondItems() {
      if (Offers.carousel_items.length > 0) {
        return Offers.items.slice(5, Offers.items.length);
      } else {
        return Offers.items.slice(6, Offers.items.length);
      }
    },
  },

  watch: {
    "Offers.city": function () {
      this.is_loading = true;
      Offers.list(true, { city: Offers.city.id }, () => {
        this.is_loading = false;
      });
    },
    filter: {
      deep: true,
      handler(bef) {
        this.is_loading = true;
        let filter = Object.assign({}, this.filter);

        filter.companies = filter.companies.map((f) => f.id);
        filter.actions = filter.actions.map((f) => f.id);
        filter.categories = filter.categories.map((f) => f.id);
        filter.showcase_location = "showcase";

        Offers.list(true, { filter: filter }, () => {
          this.is_loading = false;
        });
      },
    },
  },
  mounted() {
    this.is_loading = true;
    Offers.list(true, { showcase_location: "showcase" }, () => {
      this.is_loading = false;
    });
  },
};
</script>

<style lang="scss">
.row-form {
  margin: 0;
  .col-12,
  .col-9,
  .col {
    padding: 0;
  }
}
</style>
