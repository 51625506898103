<template>
  <swiper class="card showcase_carousel" :options="swiperOptions">
    <swiper-slide
      class="d-flex flex-column justify-content-between"
      v-for="(item, index) in items"
      :key="index"
    >
      <div
        @click="view(item)"
        class="d-flex flex-column justify-content-between"
        style="cursor: pointer"
      >
        <div class="carousel-cart">
          <div
            :style="mediaViewStyleLogoCard(item)"
            class="offer-card-logo"
          ></div>

          <p
            style="
              font-size: 18px;
              font-weight: 500;
              color: #fff;
              margin-top: 22px;
              line-height: 22px;
            "
            class="offer-card-title"
            v-html="item.title"
          ></p>
          <ul
            class="list-unstyled"
            style="
              margin-bottom: 43px;
              display: flex;
              flex-wrap: wrap;
              gap: 4px;
            "
          >
            <li style="display: inline-block">
              <b-badge
                variant="secondary"
                style="
                  border-radius: 50px;
                  padding: 4px 8px 6px 8px;
                  font-weight: 400;
                  background: rgba(0, 0, 0, 0.1);
                  color: #fff;
                "
              >
                {{ item.has_category.name }}
              </b-badge>
            </li>
            <li style="display: inline-block">
              <b-badge
                variant="secondary"
                style="
                  border-radius: 50px;
                  padding: 4px 8px 6px 8px;
                  font-weight: 400;
                  background: rgba(0, 0, 0, 0.1);
                  color: #fff;
                "
              >
                Вся РФ
              </b-badge>
            </li>
          </ul>
        </div>
        <div
          class="d-flex justify-content-between"
          style="
            border-bottom: 1px solid #569cff;
            padding: 20px; padding-top: 0px;
            margin-bottom: 84px;
          "
        >
          <div class="d-flex flex-column">
            <small style="color: #a2c9ff">Доход</small>
            <span class="fs-24 font-weight-bolder" style="width: max-content">{{
              !item.is_percentage_income
                ? item.amount.formatMoney(" ", " ", " ") + " ₽"
                : item.amount + " %"
            }}</span>
          </div>
          <div class="d-flex flex-column" style="margin: 0 20px">
            <small style="color: #a2c9ff">Срок подтверждения</small>
            <span style="font-size: 20px"
              >до {{ item.conformation_period }}
              {{
                Utils.declOfNum(item.conformation_period, [
                  "день",
                  "дня",
                  "дней",
                ])
              }}</span
            >
          </div>
        </div>
      </div>
    </swiper-slide>
    <div class="swiper-button-prev" slot="button-prev"></div>
    <div class="swiper-button-next" slot="button-next"></div>
    <div class="swiper-pagination" slot="pagination"></div>
  </swiper>
</template>

<script>
import Utils from "@/modules/utils/";

import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";

export default {
  data() {
    return {
      Utils,
      swiperOptions: {
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
          renderBullet: function (i, className) {
            return `
            <div class="${className}">
              <div class='hold left'>
                <div class='fill'></div>
              </div>
              <div class='hold right'>
                <div class='fill'></div>
              </div>
            </div>`;
          },
        },
        navigation: {
          prevEl: ".swiper-button-prev",
          nextEl: ".swiper-button-next",
        },
        autoplay: {
          delay: 5000,
          disableOnInteraction: false,
        },
        loop: true,
        watchSlidesProgress: true,
      },
    };
  },

  props: {
    items: Array,
  },

  components: {
    Swiper,
    SwiperSlide,
  },

  methods: {
    mediaViewStyleLogoCard(item) {
      return {
        height: "40px",
        width: "94px",
        backgroundImage: `url(${
          item.has_company.medias[0].thumb ||
          item.has_company.medias[0].urls.thumb
        })`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        borderRadius: "12px",
        border: "1px solid #CBD2E0",
      };
    },
    view(item) {
      this.$router.push({
        name: "user-offer-item",
        params: {
          item: item.id,
        },
      });
    },
  },

  computed: {},

  mounted() {},
};
</script>

<style lang="scss">
$tm: 2.5s; // timer

.showcase_carousel {
  .swiper-button-next,
  .swiper-button-prev {
    &:hover {
      opacity: 0.7;
    }
  }

  .swiper-button-next:after,
  .swiper-container-rtl .swiper-button-prev:after {
    content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewBox='0 0 32 32' fill='none'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M16.0002 30.6663C24.0993 30.6663 30.6668 24.1004 30.6668 15.9996C30.6668 7.90042 24.0993 1.33293 16.0002 1.33293C7.90099 1.33293 1.3335 7.90042 1.3335 15.9996C1.3335 24.1004 7.90099 30.6663 16.0002 30.6663Z' stroke='white' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M14.667 20L18.667 16L14.667 12' stroke='white' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E") !important;
  }

  .swiper-button-prev:after,
  .swiper-container-rtl .swiper-button-next:after {
    content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewBox='0 0 32 32' fill='none'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M15.9998 1.33374C7.90067 1.33374 1.33317 7.89965 1.33317 16.0004C1.33317 24.0996 7.90067 30.6671 15.9998 30.6671C24.099 30.6671 30.6665 24.0996 30.6665 16.0004C30.6665 7.89965 24.099 1.33374 15.9998 1.33374Z' stroke='white' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M17.333 12L13.333 16L17.333 20' stroke='white' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E") !important;
  }

  @keyframes left {
    0% {
      -webkit-transform: rotate(0deg);
    }

    100% {
      transform: rotate(180deg);
    }
  }

  @-webkit-keyframes left {
    0% {
      -webkit-transform: rotate(0deg);
    }

    100% {
      -webkit-transform: rotate(180deg);
    }
  }

  @keyframes right {
    0% {
      -webkit-transform: rotate(0deg);
    }

    100% {
      transform: rotate(180deg);
    }
  }

  @-webkit-keyframes right {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(180deg);
    }
  }
}

.showcase_carousel.swiper-container {
  // width: 484px;
  // height: 368px;
  border-radius: 32px;
  box-shadow: 0px 10px 14px rgb(99 109 137 / 1%);
  background: linear-gradient(180deg, #4a95ff 0%, #0069ff 100%);
  color: white;

  .swiper-pagination {
    width: unset !important;
    position: absolute;
    left: 27px;
    bottom: 30px;
  }

  .swiper-button-next,
  .swiper-button-prev {
    position: absolute;
    top: unset;
  }

  .swiper-button-prev {
    bottom: 20px;
    left: calc(100% - 92px);
  }

  .swiper-button-next {
    bottom: 20px;
    right: 20px;
  }

  .carousel-cart {
    padding: 20px;
  }

  .swiper-pagination-bullet {
    width: 8px;
    height: 8px;
    position: relative;
    background: rgba(255, 255, 255, 0.5);
    opacity: 1;

    &:hover {
      background: rgba(255, 255, 255, 0.7);
    }
  }

  .swiper-pagination-bullet-active {
    width: 12px;
    height: 12px;

    .hold {
      position: absolute;
      width: 100%;
      height: 100%;
      clip-path: inset(0px calc(100% - 12px) calc(100% - 12px) 6px);
      border-radius: 100%;
      background: rgba(255, 255, 255, 0.5);
    }

    .fill {
      position: absolute;
      width: 100%;
      height: 100%;
      border-radius: 100%;
      clip-path: inset(0px calc(100% - 6px) calc(100% - 12px) 0px);
      background-color: rgba(255, 255, 255);
    }

    .left .fill {
      z-index: 1;
      -webkit-animation: left $tm linear;
      -moz-animation: left $tm linear;
      animation: left $tm linear both;
    }

    .right {
      z-index: 3;
      -webkit-transform: rotate(180deg);
      -moz-transform: rotate(180deg);
      transform: rotate(180deg);
    }

    .right .fill {
      z-index: 3;
      -webkit-animation: right $tm linear;
      -moz-animation: right $tm linear;
      animation: right $tm linear both;
      -webkit-animation-delay: $tm;
      -moz-animation-delay: $tm;
      animation-delay: $tm;
    }
  }
}
</style>
