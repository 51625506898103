<template>
  <b-overlay
    :show="is_loading"
    blur="50"
    rounded="lg"
    spinner-lg
    spinner-variant="primary"
    variant="white"
    style=""
  >
    <transition name="zoom-fade" mode="out-in">
      <div class="cities-modal-wrapper">
        <b-input-group class="input-group-merge">
          <b-input-group-append is-text style="margin: 0">
            <feather-icon class="cursor-pointer" icon="SearchIcon" />
          </b-input-group-append>
          <b-form-input
            v-model="search"
            class="form-control-merge"
            name="search"
            placeholder="Поиск"
          />
        </b-input-group>
        <b-list-group flush class="cities-modal-content">
          <b-list-group-item
            v-for="(c, i) in filterCities"
            :key="i"
            @click="select(c)"
            :active="Offers.city ? c.id === Offers.city.id : false"
            style="cursor: pointer"
          >
            <div class="d-flex justify-content-start align-items-center">
              <div class="flex-grow-1">
                {{ c.name }}
              </div>
            </div>
          </b-list-group-item>
        </b-list-group>
      </div>
    </transition>
  </b-overlay>
</template>

<script>
import Offers from "@/modules/offers";
import Cache from "@/modules/cache";

export default {
  data() {
    return {
      Offers,
      Cache,
      is_loading: false,
      search: "",
    };
  },

  methods: {
    select(city) {
      Offers.city = city;
    },
  },

  computed: {
    isCanSend() {
      if (!this.selected) return false;

      return true;
    },
    filterCities() {
      return this.Cache.cities.filter((post) => {
        return post.name.toLowerCase().includes(this.search.toLowerCase());
      });
    },
  },

  mounted() {
    if (Cache.cities.length > 0) return;
    this.is_loading = true;
    this.$request.get("cities.list").then((rsp) => {
      Cache.setCities(rsp.items);
      this.is_loading = false;
    });
  },
};
</script>

<style lang="scss" scoped>
.list-group .list-group-item:hover {
  background-color: #f6f8fe;
}
.list-group .list-group-item.active:hover {
  background-color: var(--primary);
  border-color: var(--primary);
}
.input-group > .form-control:not(:first-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right: none;
}
</style>
